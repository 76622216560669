import React from "react"
import PropTypes from "prop-types"

import QuestionCount from "./questionCount"
import Question from "./question"
import QuestionAnswerOption from "./questionAnswerOption"

const Quiz = props => {
  return (
    <div className="quiz">
      <Question content={props.question} />
      <QuestionCount index={props.index} questionTotal={props.questionTotal} />
      <ul>{props.answerOptions.map(renderAnswerOptions)}</ul>
    </div>
  )

  function renderAnswerOptions(answerOption) {
    return (
      <QuestionAnswerOption
        key={`${props.index}_${answerOption.value}`}
        index={props.index}
        value={answerOption.value}
        content={answerOption.content}
        onAnswerSelected={props.onAnswerSelected}
      />
    )
  }
}

Quiz.propTypes = {
  index: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
}
export default Quiz
