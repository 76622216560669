import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import withData from "../components/withData"
import Quiz from "../components/quiz/quiz"
import QuizResult from "../components/quiz/quizResult"

const QUESTIONS_COUNT = 10
const ANSWERS_COUNT = 4

const QUIZ_METADATA = {
  title: `Appenzeller Quiz`,
  description: `Zeig wie gut du im Appenzeller Dialekt bist! Beantworte zehn Fragen und sehe, ob ein Stück Appenzell in dir steck!`,
  author: `@petermanser`,
  keywords: [
    `appenzell`,
    `appenzeller dialekt`,
    `dialekt`,
    `wörter`,
    `sprichwörter`,
    `wörterbuch`,
    `quiz`,
  ],
}

class QuizPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this)
    this.handleReset = this.handleReset.bind(this)

    this.state = {
      index: 0,
      correctAnswersCount: 0,
      finished: false,
      answers: [],
      questions: this.getQuestions(),
    }
  }

  getQuestions = () => {
    const randomWords = this.props.data.slice(0)

    randomWords.sort(function() {
      return 0.5 - Math.random()
    })

    const questions = []
    for (let i = 0; i < QUESTIONS_COUNT; i++) {
      const windowStart = Math.min(
        randomWords.length - ANSWERS_COUNT - 1,
        Math.floor(Math.random() * randomWords.length)
      )

      const answers = randomWords
        .slice(windowStart, windowStart + ANSWERS_COUNT)
        .map(answer => this.mapAnswer(answer.node))

      const currentAnswer = randomWords[i].node

      if (!answers.some(answer => currentAnswer.id === answer.value)) {
        answers[Math.floor(Math.random() * ANSWERS_COUNT)] = this.mapAnswer(
          currentAnswer
        )
      }

      questions.push({
        question: currentAnswer.word,
        correctAnswer: this.mapAnswer(currentAnswer),
        answers: answers,
      })
    }

    return questions
  }

  handleReset = () => {
    this.setState({
      index: 0,
      correctAnswersCount: 0,
      finished: false,
      answers: [],
      questions: this.getQuestions(),
    })
  }

  handleAnswerSelected = event => {
    this.setUserAnswer(event.currentTarget.value)

    if (this.state.index + 1 < this.state.questions.length) {
      setTimeout(() => this.setNextQuestion(), 300)
    } else {
      setTimeout(() => this.setFinished(), 300)
    }
  }

  setUserAnswer = answer => {
    const correctAnswersCount =
      this.state.correctAnswersCount +
      (answer === this.getCurrrentQuestion().correctAnswer.value)

    const answers = this.state.answers
    answers.push(answer)

    this.setState({
      correctAnswersCount,
      answers,
    })
  }

  setNextQuestion = () => {
    const index = this.state.index + 1

    this.setState({
      index: index,
    })
  }

  setFinished() {
    this.setState({ finished: true })
  }

  mapAnswer = node => ({
    value: node.id,
    content: node.meaning,
  })

  getCurrrentQuestion = () => this.state.questions[this.state.index]

  render() {
    return (
      <Layout title={QUIZ_METADATA.title}>
        <SEO
          title={QUIZ_METADATA.title}
          description={QUIZ_METADATA.description}
          keywords={QUIZ_METADATA.keywords}
        />
        <div>
          {this.state.finished ? this.renderResult() : this.renderQuiz()}
        </div>
      </Layout>
    )
  }

  renderQuiz = () => {
    return (
      <Quiz
        index={this.state.index}
        questionTotal={this.state.questions.length}
        question={this.getCurrrentQuestion().question}
        answerOptions={this.getCurrrentQuestion().answers}
        onAnswerSelected={this.handleAnswerSelected}
      />
    )
  }
  renderResult = () => {
    return (
      <QuizResult
        questionTotal={this.state.questions.length}
        answers={this.state.answers}
        questions={this.state.questions}
        correctAnswersCount={this.state.correctAnswersCount}
        onReset={this.handleReset}
      />
    )
  }
}

export default withData(QuizPage)
