import React from "react"
import PropTypes from "prop-types"

const imgOk = require(`../../images/ok.png`)
const imgNok = require(`../../images/nok.png`)

const QuizResult = props => {
  let title = "Perfekt!"
  if (props.correctAnswersCount < props.questionTotal * 0.3) {
    title = "Trainieren, trainieren, trainieren..."
  } else if (props.correctAnswersCount < props.questionTotal * 0.5) {
    title = "Noch nicht ganz da..."
  } else if (props.correctAnswersCount < props.questionTotal * 0.7) {
    title = "Es kommt..."
  } else if (props.correctAnswersCount < props.questionTotal) {
    title = "Bravo!"
  }
  return (
    <div>
      <h2>{title}</h2>
      <div>
        <span>
          Du hast <strong>{props.correctAnswersCount}</strong> von{" "}
          <strong>{props.questionTotal}</strong> richtig beantwortet!
        </span>
        <table style={{ marginTop: `2rem` }}>
          <thead>
            <tr>
              <th style={{ width: `12%` }} />
              <th>Wort</th>
              <th>Bedeutung</th>
            </tr>
          </thead>
          <tbody>
            {props.questions.map((question, i) => {
              return (
                <tr key={i}>
                  <td>
                    <img
                      style={{
                        margin: `0.25rem 0.25rem 0 0`,
                        minWidth: `20px`,
                        width: `20px`,
                      }}
                      src={
                        props.answers[i] === question.correctAnswer.value
                          ? imgOk
                          : imgNok
                      }
                      alt={
                        props.answers[i] === question.correctAnswer.value
                          ? `Richtig`
                          : `Falsch`
                      }
                    />
                  </td>
                  <td
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {question.question}
                  </td>
                  <td>
                    {props.answers[i] !== question.correctAnswer.value ? (
                      <div>
                        <span
                          style={{
                            textDecoration: `line-through`,
                            color: `#e30513`,
                          }}
                        >
                          {
                            question.answers.filter(
                              answer => answer.value === props.answers[i]
                            )[0].content
                          }
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <span>{question.correctAnswer.content}</span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <button
        onClick={props.onReset}
        style={{
          background: `none`,
          border: 0,
          fontWeight: 700,
          padding: `1rem 0`,
          color: `#e30513`,
        }}
      >
        Nochmals
      </button>
    </div>
  )
}

QuizResult.propTypes = {
  questionTotal: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  correctAnswersCount: PropTypes.number.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default QuizResult
