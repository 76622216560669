import React from "react"
import PropTypes from "prop-types"

const QuestionAnswerOption = props => {
  const id = `${props.index}_${props.value}`

  return (
    <li key={id} style={{ marginLeft: `1.6rem`, textIndent: `-1.6rem` }}>
      <input
        type="radio"
        name="answers"
        id={id}
        value={props.value}
        onChange={props.onAnswerSelected}
      />
      <label htmlFor={id} style={{ marginLeft: `.75rem` }}>
        {props.content}
      </label>
    </li>
  )
}

QuestionAnswerOption.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
}

export default QuestionAnswerOption
