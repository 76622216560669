import React from "react"
import PropTypes from "prop-types"

const QuestionCount = props => {
  return (
    <div style={{ marginBottom: `1.5rem` }}>
      <span>
        {props.index + 1} von {props.questionTotal}
      </span>
    </div>
  )
}
QuestionCount.propTypes = {
  index: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
}
export default QuestionCount
